<template>
	<div class="dispute-dropdown">
		<button type="button" class="dispute-dropdown-button">
			<img src="/assets/images/svg/shield.svg" alt="">
			<span>{{ $t('interface.a_secure_deal') }}</span>
		</button>
		<div class="dispute-dropdown-content-box">
			<div class="dispute-dropdown-content">
				<div class="dispute-dropdown-content-group">
					<img src="/assets/images/svg/clock-green.svg" alt="">
					<p>{{ $t('product.pay_modal.dispute.dispute_time') }}</p>
				</div>
				<p class="dispute-dropdown-content-title">{{ contentTitle }}</p>
				<p class="dispute-dropdown-content-text">{{ contentText }}</p>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isLoggedIn } = storeToRefs(useUserStore())

const contentTitle = computed(() => {
  return isLoggedIn.value ? t('product.pay_modal.dispute.logged_in.dispute_fast_pay_title') : t('product.pay_modal.dispute.dont_logged_in.dispute_fast_pay_title');
})
const contentText = computed(() => {
  return isLoggedIn.value ? t('product.pay_modal.dispute.logged_in.dispute_fast_pay_subtitle') : t('product.pay_modal.dispute.dont_logged_in.dispute_fast_pay_subtitle');
})
</script>

<style lang="scss" scoped>
.dispute-dropdown {
	position: relative;

	.dispute-dropdown-content-box {
		display: none;
	}

	&:hover {
		.dispute-dropdown-content-box {
			display: flex;
		}
	}
}

.dispute-dropdown-button {
	display: flex;
	align-items: center;
	padding: 5px 11px 5px 8px;
	border-radius: 100px;
	background: rgba(20, 142, 42, 0.2);
	font-weight: 600;
	font-size: 16px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #107422;
	cursor: pointer;

	img {
		width: 24px;
		height: 24px;
	}
}

.dispute-dropdown-content-box {
	width: 261px;
	position: absolute;
	top: 100%;
	right: 0;
	padding-top: 4px;
}

.dispute-dropdown-content {
	display: flex;
	flex-direction: column;
	gap: 14px;

	border: 1px solid rgba(20, 142, 42, 0.2);
	border-radius: 17px;
	box-shadow: 0 4px 6px 0 rgba(77, 24, 13, 0.1);
	background: #e8f4ea;
	padding: 17px 15px 15px;
}

.dispute-dropdown-content-group {
	display: flex;
	gap: 5px;
	font-weight: 700;
	font-size: 16px;
	line-height: 80%;
	letter-spacing: -0.03em;
	color: #148e2a;
	max-width: 180px;
}

.dispute-dropdown-content-title {
	font-weight: 700;
	font-size: 15px;
	line-height: 110%;
	letter-spacing: -0.03em;
	color: #148e2a;
}

.dispute-dropdown-content-text {
	font-weight: 600;
	font-size: 13px;
	line-height: 110%;
	letter-spacing: -0.03em;
	color: #148e2a;
}

@media (max-width:1023px) {
	.dispute-dropdown-button {
		padding: 4px;

		span {
			display: none;
		}

		img {
			width: 18px;
			height: 18px;
		}
	}

}
</style>